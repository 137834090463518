/** @format */

import { Asset } from 'contentful';
import { useRouter } from 'next/router';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';
import theme from '../../theme/styled-theme';
import { InfoMap } from '../../types/page';
import { Image } from '../Image';
import { Container } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { DataNumbers, DataUnitMeasure, Subtitle } from '../ui/Typography';

interface StyledProps {
  color?: number;
  bgImage?: Asset;
  map?: Asset;
}

const AllContainer = styled.div`
  background-color: ${({ theme }) => theme.color.teal.dark};
  width: 100%;
  margin-bottom: 6rem;
  overflow-x: hidden;
  ${breakpointUpTo.md`
  margin-bottom: 3.75rem;
  `}
`;

const SyledContainer = styled(Container)`
  overflow-y: clip;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.teal.dark};
  ${breakpointUpTo.lg`

  `}
`;

const WrapperAllMap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const WrapperActiveMap = styled.div`
  img {
    z-index: 99;
    left: -10rem;
    top: -8rem;
    position: absolute;
  }
  ${breakpointUpTo.xl`
    display:none;
  `}
`;

const WrapperMap = styled.div`
  ${breakpointUpTo.xl`
    display:none
  `}
  width: 100%;
  height: 100%;
  position: relative;
  mix-blend-mode: multiply;
  transform: transalte3d(0, 0, 0);

  img {
    left: -10rem;
    top: -8rem;
    position: absolute;
  }
`;

const WrapperTitle = styled(Container)`
  padding: 10rem 0 5rem 0 !important;
  display: flex;
  text-align: center;
  justify-content: center;
  h2 {
    color: ${({ theme }) => theme.color.white};
  }
`;
const WrapperBody = styled.div`
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  ${breakpointUpTo.xl`
  flex-direction:column;
 margin-bottom: 5rem;
  `}
`;

const ContainerMap = styled.div`
  width: 100%;
  ${breakpointUpTo.xl`
 display:none;
  `}
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const WrapperMapMobile = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  img {
    width: 100%;
    height: 100%;
  }
  ${breakpointUpTo.xl`
 display:block;
  `}
`;
const MapMobile = styled(Image)`
  display: none;

  ${breakpointUpTo.xl`
 display:block;
  `};
`;

const WrapperData = styled.div`
  gap: 4rem;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: content;
  align-items: baseline !important;

  ${breakpointUpTo.xl`
  flex-wrap: nowrap;
  width:100%
  `}
  ${breakpointUpTo.md`
  flex-wrap: wrap;
  align-content:center;
 justify-content: center;
    align-content: center;
    width: 100%;
  `}
  ${breakpointUpTo.sm`
  flex-direction: column;
  `}
`;

const ContainerRightData = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  text-align: center !important;
  ${breakpointUpTo.xl`
 width:100%;
  `}
  ${breakpointUpTo.md`
 width:30%;
  `}
  ${breakpointUpTo.sm`
 width:100%;
  `}
`;

const ContainerLegend = styled(Container)`
  padding: 4rem 0;
`;

const WrapperLegendData = styled.div`
  padding-bottom: 1rem !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  display: flex;
  flex-direction: column;
  width: 50%;
  ${breakpointUpTo.xl`
 width:100%;
  `}
`;

const BodyFirstLegend = styled.h5`
  font-weight: 100 !important;
  margin-left: 40px;
  color: ${({ theme }) => theme.color.white};
`;
const BodyLegend = styled.div`
  justify-content: start !important;
  display: flex;
  h5 {
    color: ${({ theme }) => theme.color.white};
  }
`;

const Circle = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  padding: 0 !important;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  border: 2px solid white;
  background-color: ${({ color, theme }) => (color !== 0 ? theme.color.pink : theme.color.teal.light)};
`;
const MiniCircle = styled.div`
  padding: 0 !important;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.teal.dark};
`;
const Example = styled.div`
  display: contents;
  ${breakpointUpTo.xl`
  width:100%;
  display: flex;
  flex-direction: column-reverse;
  `}
`;

export const SectionInfoMap: FC<Props> = ({ data }) => {
  const { locale } = useRouter();
  return (
    <AllContainer>
      <SyledContainer>
        <Example>
          <WrapperAllMap>
            <WrapperActiveMap>
              <Image image={data.mapImage} />
            </WrapperActiveMap>
            <WrapperMapMobile>
              <MapMobile image={data.mapMobile} />
            </WrapperMapMobile>
            <WrapperMap>
              <Image image={data.bgImage} />
            </WrapperMap>
          </WrapperAllMap>
          <WrapperTitle>
            <RichText text={data.title} />
          </WrapperTitle>
        </Example>
        <Example>
          <WrapperBody>
            <ContainerMap />
            <WrapperData>
              {data.statisticsData &&
                data.statisticsData.map(statistics => (
                  <ContainerRightData key={statistics.value}>
                    <DataNumbers $color={theme!.color.white}>
                      {statistics.prefix && (
                        <DataUnitMeasure color={theme!.color.white}>{statistics.prefix}</DataUnitMeasure>
                      )}

                      {parseInt(statistics.value).toLocaleString(locale)}
                      {statistics.suffix && (
                        <DataUnitMeasure color={theme!.color.white}>{statistics.suffix}</DataUnitMeasure>
                      )}
                    </DataNumbers>
                    <Subtitle $color={theme!.color.yellow}>{statistics.label}</Subtitle>
                  </ContainerRightData>
                ))}
            </WrapperData>
          </WrapperBody>
          <ContainerLegend>
            {data.infoLegend &&
              data.infoLegend.map((el, index) => (
                <WrapperLegendData key={el.subtitle}>
                  <BodyLegend>
                    <Circle color={index}>{index === 0 && <MiniCircle />}</Circle>
                    <h5>{el.title}</h5>
                  </BodyLegend>
                  <BodyFirstLegend>{el.subtitle}</BodyFirstLegend>
                </WrapperLegendData>
              ))}
          </ContainerLegend>
        </Example>
      </SyledContainer>
    </AllContainer>
  );
};

type Props = {
  data: InfoMap['fields'];
};
